Mosaic.addPlugins([require('/home/elektra_lt/projects/elektra/production/releases/53/app/design/frontend/PlugAndSell2/pwa/packages/contractors-account-managers/src/plugin/StoreIndex.plugin.ts'),require('/home/elektra_lt/projects/elektra/production/releases/53/app/design/frontend/PlugAndSell2/pwa/packages/contractors/src/plugin/StoreIndex.plugin.ts'),require('/home/elektra_lt/projects/elektra/production/releases/53/app/design/frontend/PlugAndSell2/pwa/packages/blog/src/plugin/StoreIndex.plugin.js')]);
import { Reducer } from 'redux';

import BreadcrumbsReducer from 'Store/Breadcrumbs/Breadcrumbs.reducer';
import CartReducer from 'Store/Cart/Cart.reducer';
import CategoryReducer from 'Store/Category/Category.reducer';
import CheckoutReducer from 'Store/Checkout/Checkout.reducer';
import CmsReducer from 'Store/Cms/Cms.reducer';
import ConfigReducer from 'Store/Config/Config.reducer';
import ContactFormReducer from 'Store/ContactForm/ContactForm.reducer';
import MetaReducer from 'Store/Meta/Meta.reducer';
import MyAccountReducer from 'Store/MyAccount/MyAccount.reducer';
import NavigationReducer from 'Store/Navigation/Navigation.reducer';
import NoMatchReducer from 'Store/NoMatch/NoMatch.reducer';
import OfflineReducer from 'Store/Offline/Offline.reducer';
import OverlayReducer from 'Store/Overlay/Overlay.reducer';
import PopupReducer from 'Store/Popup/Popup.reducer';
import ProductCompareReducer from 'Store/ProductCompare/ProductCompare.reducer';
import ProductListInfoReducer from 'Store/ProductListInfo/ProductListInfo.reducer';
import StoreInPickUpReducer from 'Store/StoreInPickUp/StoreInPickUp.reducer';
import UrlRewritesReducer from 'Store/UrlRewrites/UrlRewrites.reducer';
import WishlistReducer from 'Store/Wishlist/Wishlist.reducer';

import AnnouncementReducer from '../../packages/announcement/src/store/Announcement/Announcement.reducer';
import AuthorReducer from '../../packages/blog/src/store/Author/Author.reducer';
import CategoriesReducer from '../../packages/blog/src/store/Categories/Categories.reducer';
import PostsReducer from '../../packages/blog/src/store/Posts/Posts.reducer';
import PostsDetailsReducer from '../../packages/blog/src/store/PostsDetails/PostsDetails.reducer';
import PostsSearchReducer from '../../packages/blog/src/store/PostsSearch/PostsSearch.reducer';
import TagsReducer from '../../packages/blog/src/store/Tags/Tags.reducer';
import GoogleTagManagerReducer from '../../packages/gtm-new/src/store/GoogleTagManager/GoogleTagManager.reducer';

export type StaticReducerKeys =
    | 'ProductListReducer'
    | 'ProductListInfoReducer'
    | 'CartReducer'
    | 'WishlistReducer'
    | 'NoMatchReducer'
    | 'MyAccountReducer'
    | 'NavigationReducer'
    | 'OverlayReducer'
    | 'OfflineReducer'
    | 'PopupReducer'
    | 'UrlRewritesReducer'
    | 'ConfigReducer'
    | 'MetaReducer'
    | 'CheckoutReducer'
    | 'ContactFormReducer'
    | 'ProductCompareReducer'
    | 'StoreInPickUpReducer'
    | 'BreadcrumbsReducer'
    | 'ProductReducer'
    | 'CategoryReducer'
    | 'CmsReducer'
    | 'PostsDetailsReducer'
    | 'PostsReducer'
    | 'PostsSearchReducer'
    | 'CategoriesReducer'
    | 'TagsReducer'
    | 'AuthorReducer'
    | 'GoogleTagManagerReducer'
    | 'AnnouncementReducer'
    | 'ContractorsReducer';

/** @namespace PlugAndSell2/Store/Index/getStaticReducers */
export const getStaticReducers = (): Record<StaticReducerKeys, Reducer> =>
    ({
        ProductListInfoReducer,
        CartReducer,
        WishlistReducer,
        NoMatchReducer,
        MyAccountReducer,
        NavigationReducer,
        OverlayReducer,
        OfflineReducer,
        PopupReducer,
        UrlRewritesReducer,
        ConfigReducer,
        MetaReducer,
        CheckoutReducer,
        ContactFormReducer,
        ProductCompareReducer,
        StoreInPickUpReducer,
        BreadcrumbsReducer,
        CategoryReducer,
        CmsReducer,
        /* TODO: should be taken from blog plugin but couldn't trigger StoreIndex.plugin */
        PostsDetailsReducer,
        PostsReducer,
        PostsSearchReducer,
        CategoriesReducer,
        TagsReducer,
        AuthorReducer,
        /* *** */
        /* TODO: should injected from plugin */
        GoogleTagManagerReducer,
        AnnouncementReducer,
        /* *** */
    } as Record<StaticReducerKeys, Reducer>);
